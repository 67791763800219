.navbar {
  font-weight: 400;
  letter-spacing: .1em;

  &__list {
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  } 

  &__link {
    text-transform: uppercase;
    transition: color 0.3s;

    &:hover, &:active, &--active {
        color: black;
    }

    &:hover {
      text-decoration: underline;
    }

    &:active, &--active {
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__item {
    white-space: nowrap;
    position: relative;
    z-index: 2;
  }

  @mixin dropdown-normal {
    visibility: visible;
    opacity: 1;
    padding-left: 1em;
  }

  &__item:hover &__dropdown {
    @include dropdown-normal;
  }

  &__dropdown {
    padding: 0;
    padding-right: 0.2em;
    padding-top: 0.25em;
    list-style-type: none;
    position: absolute;
    top: 1em;
    // Animation
    visibility: hidden;
    opacity: 0;
    //transition: line-height 0.3s, visibility 0s, opacity 0.3s;;
    transition: all 0.3s;
    background: rgba(255, 255, 255, 0.7);
    padding-left: 0em;

    &--active {
      //@include dropdown-normal;
      //position: static;
    }
  }

  &__dropdownitem {
    margin-top: 0.3em;
    margin-bottom: 0.3em;
  }

  &__mobilebtnlabel {
    display: none;
    cursor: pointer;
    width: 1.5em;
    height: 1.5em;
    margin: 0.5em;
    border-right: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(225deg);
    transition: all 0.3s;
  }

  &__mobilebtn {
    display: none;
  }

  // Checked = menu is closed
  &__mobilebtn:checked ~ &__mobilebtnlabel {
    transform: rotate(45deg);
  }


  @media screen and ( max-width: $mobileWidth ) {
    &__mobilebtnlabel {
      display: block;
    }

    &__mobilebtn:checked ~ &__list {
      line-height: 0;
      opacity: 0;
    }

    &__mobilebtn:checked ~ &__list * {
      display: none;
    }

    &__list {
      flex-direction: column;
      align-items: flex-start;
      line-height: 1.1em;
      opacity: 1;
      transition: line-height 0.3s, opacity 0.3s;
    }

    &__dropdown {
      @include dropdown-normal;
      position: static;
      margin-left: 1em;
      margin-bottom: 1em;
    }
  }
}

// Needed for flickity to work nicely
.is-flickity-fullscreen .navbar__item {
  z-index: -1;
}
