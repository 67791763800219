.art-image {
  max-height: 70vh;
  object-fit: contain;
  top: 50%;
  transform: translateY(-50%);
}

.carousel.is-fullscreen .art-image {
  max-height: 100vh;
  object-fit: contain;
}

