.art-description  {
    &__titlebox {
        margin: 2em;
    }

    &__title {
        font-size: 1em;
        font-style: italic;
        display: inline;
    }

    &__metadata {
        font-size: 1em;
        display: inline;
    }

    &__metadatabox {
        text-align: center;
        margin: 0 auto;
    }

    &__description {
        white-space: pre-line;
    }
}
