body {
  background: $backgroundColor;
  color: $bodyColor;
  font-family: $bodyFont;
  font-weight: 400;
  margin: 0 auto;
  width: 95%;
  max-width: $siteWidth;
  // Below 3 lines make the footer display properly
  height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  background: $backgroundColor;
  color: grey;
  padding-bottom: 1px;
}

main {
  margin: 0 auto;
  width: 100%;
}

img {
  width: 100%;
}

footer {
  font-size: 75%;
  margin-top: auto;
  color: grey;
  display: flex;
  justify-content: space-between;
}

@media screen and ( max-width: $mobileWidth ) {
  footer {
    display: block;
  }
}

