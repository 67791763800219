// Following ITCSS
@import 'settings'; // variables and fonts
@import 'generic'; // resets and setup
@import 'elements'; // styling for base css elements

// Components
@import 'components/title';
@import 'components/nav';
@import 'components/artlist';
@import 'components/artimage';
@import 'components/artdescription';
@import 'components/artistprofile';
@import 'components/cv';
@import 'components/contact';

