.art-list {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    
    &__figure {
      display: block;
      margin: 0.2em;
      height: 30vmin;
      position: relative;
      transition: background 0.3s ease-in-out;
    }

    &__figure:hover {
        background: rgba(255, 255, 255, 0.5);
    }

    &__image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      // these lines hide the image behind the hover effect on figure
      position: relative;
      z-index: -2;
    }

    &__caption {
        position: absolute;
        left: 1em;
        top: 82%;
        font-weight: 400;
        font-size: 1em;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 1);
        color: white;
    }

  &--front {
    grid-template-columns: 1fr 1fr 1fr !important;
  }

  @media screen and ( max-width: $mobileWidth ) {
    grid-template-columns: 1fr;
  }
}
